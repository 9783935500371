import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue';
import Contact from "@/views/Contact";
import UserList from "@/views/user/List";
import UserShow from "@/views/user/Show";
import Playlist from "@/views/Playlist";
import Roadmap from "@/views/Roadmap";
import ArticleList from "@/views/article/List";
import ArticleShow from "@/views/article/Show";
import FamilyList from "@/views/article/FamilyList";
import ArticleFamilyShow from "@/views/article/ArticleFamilyShow";
import PodcastList from "@/views/article/PodcastList";
import PodcastShow from "@/views/article/PodcastShow";
import Search from "@/views/Search";
import Site from "@/views/Site";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/!',
    name: 'Home2',
    component: Home
  },
  {
    path: '',
    name: 'Home3',
    component: Home
  },
  {
    path: '/kontakt',
    name: 'Contact',
    component: Contact,
    metaTags: [
      {
        name: 'description',
        content: 'The login page has a description, maybe.'
      },
      {
        property: 'og:description',
        content: 'The login page has an open graph description, too, maybe.'
      }
      ]
  },
  {
    path: '/off-ludzie/:slug',
    name: 'UserShow',
    component: UserShow
  },
  {
    path: '/off-ludzie',
    name: 'UserList',
    component: UserList,
  },
  {
    path: '/playlist',
    name: 'Playlist',
    component: Playlist,
  },
  {
    path: '/ramowka',
    name: 'Roadmap',
    component: Roadmap,
  },
  {
    path: '/newsy',
    name: 'ArticleList',
    component: ArticleList,
  },
  {
    path: '/newsy/:slug',
    name: 'ArticleShow',
    component: ArticleShow,
    props: true
  },
  {
    path: '/audycje',
    name: 'FamilyList',
    component: FamilyList,
    props: true
  },
  {
    path: '/audycje/:slug',
    name: 'ArticleFamilyShow',
    component: ArticleFamilyShow,
    props: true
  },
  {
    path: '/podcasty',
    name: 'PodcastList',
    component: PodcastList,
    props: true
  },
  {
    path: '/podcasty/:slug',
    name: 'PodcastShow',
    component: PodcastShow,
    props: true
  },
  {
    path: '/szukaj/:text',
    name: 'Search',
    component: Search,
    props: true
  },
  {
    path: '/:slug',
    name: 'Site',
    component: Site,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
    base: '/',
    routes
})


router.beforeEach((to, from, next) => {

  /**
   * Określa czy element docelowy wymaga autoryzacji
   * @type {boolean}
   */
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  /**
   * Określa czy element docelowy jest dostępny tylko bez autoryzacji
   * @type {boolean}
   */
  const requiresNoAuth = to.matched.some(record => record.meta.requiresNoAuth);

  /**
   * Wartość tokena z localStorage
   * @type {string}
   */
  const token = localStorage.getItem('token');

  /**
   * Jeśli strona wymaga autoryzacji a obecnie jej nei ma przenosi do logowania
   */
  if (requiresAuth && !token) {
    next('/login');
  }

  /**
   * Jeśli strona nie chce autoryzowanych użytkowników a obecnie jest, przejdź moje konto
   */
  else if( requiresNoAuth && token ){
    next('');
  }

  /**
   * W innym wypadku przejdź tam gdzie szedłeś
   */
  else {
    next();
  }
});

export default router
