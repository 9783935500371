<template>

  <main class="podcast-list">

    <div class="container">
      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Off Radio Kraków" class="breadcrumb">
            Off Radio Kraków
          </router-link>

          <router-link to="/podcasty" title="Podcasty" class="breadcrumb">
            Podcasty
          </router-link>
        </div>
      </div>


      <div class="row">
        <div class="col s12">
          <h1 class="big-title">Podcasty</h1>
        </div>
      </div>


      <div class="row">
        <div class="col s12">
          <div class="filter">

            <div class="row mb-0">
              <div class="col s6 l4">

                <div class="filter-block">
                  <input name="id_user" type="hidden" v-model="id_user">
                  <div class="filter-block-head" v-on:click="filterHeadClick">Prowadzący</div>
                  <ul class="filter-block-body">
                    <li class="filter-block-body-item" v-for="user in users"
                        :class="{ 'filter-block-body-item-selected': (id_user === user.id)  }"
                        :data-value="user.id"
                        v-on:click="filterItemClick"
                    >
                      <div class="filter-block-body-item-thumb"
                           :style="{ backgroundImage: `url(${user.avatar_url})` }"></div>
                      <h4 class="filter-block-body-item-name">{{ user.firstname }} {{ user.lastname }}</h4>
                      <span class="filter-block-body-item-position">{{ user.position }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col s6 l4">

                <div class="filter-block">
                  <input name="id_family" type="hidden" v-model="id_family">
                  <div class="filter-block-head" v-on:click="filterHeadClick">Audycje</div>
                  <ul class="filter-block-body">

                    <li class="filter-block-body-item" v-for="family in familys"
                        :class="{ 'filter-block-body-item-selected': (id_family === family.id)  }"
                        :data-value="family.id"
                        v-on:click="filterItemClick"
                    >
                      <div class="filter-block-body-item-thumb"
                           :style="{ backgroundImage: `url(${family.thumb})` }"></div>
                      <h4 class="filter-block-body-item-name-full">{{ family.title }}</h4>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col s12">

          <div class="card white darken-1" v-if="articles && articles.length == 0">
            <div class="card-content black-text">
              <p>Brak</p>
            </div>
          </div>

          <PodcastLine v-for="article in articles" :article="article"/>

          <Pagination :page="page" :count="count"/>

        </div>
      </div>


    </div>

    <SectionInfo code="broadcastlist"/>
  </main>
</template>

<script>
import axios from "@/axios";
import PodcastLine from "@/views/article/PodcastLine";
import SectionInfo from "@/views/home/SectionInfo";
import Pagination from "@/components/Pagination";

export default {
  name: "PodcastList",
  components: {Pagination, PodcastLine, SectionInfo},
  data() {
    return {
      'id_user': null,
      'id_family': null,
      'users': null,
      'familys': null,
      'page': 1,
      'count': null,
      'articles': null,
    }
  },
  methods: {
    filterItemClick: function (e, e2) {

      let item = e.target.parentNode;
      let id = item.dataset.value;

      if (item.classList.contains('filter-block-body-item-selected')) {
        id = null;
      }

      let block = e.target.parentNode.parentNode.parentNode
      block.classList.toggle("filter-block-open");

      let name = block.querySelector('input').name;

      if (name == 'id_family')
        this.id_family = id;

      if (name == 'id_user')
        this.id_user = id;

      this.refresh();


    },
    filterHeadClick: function (e) {
      let block = e.target.parentNode
      block.classList.toggle("filter-block-open");
    },
    refresh: function () {

      let domain = "https://off.radiokrakow.pl/api/"
      let domain_new = "https://off.radiokrakow.pl/"

      axios
          .get('article/get', {
            params: {
              scope: 'title,block_thumb,thumb,url,date,audio,audio_url,audio_time,text,thumb_url',
              'type': 'broadcast',
              'id_user_only': this.id_user,
              'id_family': this.id_family,
              'page': this.page,
              'count': 20,
              'is_show': true
            }
          })
          .then(response => {

            let data = response.data;

            $.each(data.data, function (key, val) {
              val.thumb = domain_new + val.thumb.substr(domain.length)

              val.block_thumb = domain_new + val.block_thumb.substr(domain.length)

              if (val.audio_url)
                val.audio_url = domain_new + val.audio_url.substr(domain.length)


            });

            this.count = data.count;
            this.articles = data.data;
          })
    }
  },
  created() {

    let domain = "https://off.radiokrakow.pl/api/"
    let domain_new = "https://off.radiokrakow.pl/"

    axios
        .get('user/get', {
          params: {
            scope: 'id,firstname,lastname,name,avatar_url,url_full',
            sort: 'lastname'
          }
        })
        .then(response => {

          $.each(response.data.data, function (key, val) {

            val.avatar_url = domain_new + val.avatar_url.substr(domain.length)

          })

          this.users = response.data.data;

        });

    axios
        .get('articlefamily/get', {params: {scope: 'thumb,title,url,id', sort: 'title'}})
        .then(response => {

          $.each(response.data.data, function (key, val) {

            val.thumb = domain_new + val.thumb.substr(domain.length)

          })

          this.familys = response.data.data;

        })

    this.refresh();

  },

  watch: {
    page: function (val) {
      this.refresh();
    },
  },

}
</script>

<style scoped>

</style>