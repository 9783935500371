<template>
  <div class="radio_player" v-show="is_show">
    <div class="radio_player__thumb" :style="{ backgroundImage: `url(${family_thumb})` }"></div>
    <div class="radio_player__name">{{ family_name }}</div>
    <div class="radio_player__song" >{{ song_name }}</div>
    <div class="radio_player__artist">{{ song_artist }} </div>
    <div class="radio_player__play" id="radio_player__play" v-on:click="play" v-bind:class="{ played: is_played }">
      <i class="material-icons play">play_arrow</i>
      <i class="material-icons pause">pause</i>
    </div>

  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: 'RadioPlayer',
  props: {
    msg: String
  },
  data() {
    return {
      is_show: false,
      is_played: false,
      // url: "http://stream2.nadaje.com:13332/offradiokrakow",
      url: "https://stream2.nadaje.com:13333/offradiokrakow",
      audio: null,
      family_name: null,
      family_thumb: null,
      song_name: null,
      song_artist: null,
    }
  },
  created: function () {
    this.refreshRoadmap();
    this.refreshSong();
  },
  methods: {

    play: function () {

      if (this.is_played) {
        this.stop();
      } else {

        if(!this.audio){
          this.audio = new Audio(this.url);
        }

        this.audio.play();
        this.is_played = true;
      }

      this.$root.$refs.audioPlayer.stop()
      this.$root.$refs.audioPlayer.hide()
    },
    stop: function () {

      let audio = this.audio;

      if(audio){

        audio.pause();
        audio.src = audio.src;
      }

      this.is_played = false;

    },
    open: function () {
      this.is_show = true;

      if (!this.is_played)
        this.play()

    },
    close: function () {
      this.is_show = false;

      if (this.is_played)
        this.stop()

    },
    hide: function () {
      this.is_show = false;

      if (this.is_played)
        this.stop()

    },
    refreshRoadmap: function () {


      let self = this;

      axios
          .get('articleroadmap/now')
          .then(response => {

                let data = response.data;

                let now = data.now;


                let domain = "https://off.radiokrakow.pl/api/"
                let domain_new = "https://off.radiokrakow.pl/"

                now.thumb = domain_new + now.thumb.substr(domain.length)

                this.family_name = now.name;
                this.family_thumb = now.thumb;

                setTimeout(function () {
                  self.refreshRoadmap();
                }, 60000);

              }
          );

    },
    refreshSong: function () {


      let self = this;

      axios
          .get('index/song')
          .then(response => {

                let data = response.data;

                this.song_name = data.song;
                this.song_artist = data.artist;

                setTimeout(function () {
                  self.refreshSong();
                }, 60000);

              }
          );

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.radio_player {
  position: fixed;

  left: 0px;
  bottom: 0px;
  right: 0px;

  height: 96px;

  padding-left: 96px;

  z-index: 20000;

  border-top: 1px solid white;

  background-color: #000;

  min-width: 400px;

  width: 100%;

  /*display: none;*/
}

.radio_player.radio_player-open {
  display: block;
}

.radio_player__thumb {

  width: 80px;
  height: 80px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: absolute;


  left: 8px;
  top: 8px;
}

.radio_player__name {
  font-weight: 700;
  font-size: 18px;
  padding-top: 22px;
}

.radio_player__artist {
  display: inline-block;
  opacity: .7;
}

.radio_player__song {
  display: inline-block;
  margin-right: 6px;
}

.radio_player__play {
  position: absolute;

  right: 8px;
  top: 50%;
  transform: translateY(-50%);

  cursor: pointer;
}

.radio_player__play .pause {
  display: none;
}

.radio_player__play .play {
  display: block;
}

.radio_player__play.played .pause {
  display: block;
}

.radio_player__play.played .play {
  display: none;
}
</style>
