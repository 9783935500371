<template>
  <section class="article-section">


    <div class="container-fluid">
      <div class="row">
        <div class="col s12">
          <div class="title-line mb-3">
            <h2 v-if="url">
              <router-link :to="url" class="title-line__link">

                {{ name }}
              </router-link>
            </h2>
            <h2 v-if="!url">
              {{ name }}
            </h2>
          </div>
        </div>
      </div>
      <div class="row">

        <div class="col s12 m6 l4 xl3" v-for="article in articles">

          <ArticleBlock :article="article"/>

        </div>


      </div>
    </div>

  </section>
</template>

<script>
import axios from "@/axios";
import {Carousel, Slide, Pagination} from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';
import ArticleBlock from "@/views/article/ArticleBlock";

export default {
  name: "ArticleSection",
  props: ['code'],
  components: {
    ArticleBlock,
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      articles: null,
      name: null,
      url: null,

    }
  },

  created: function () {


    axios
        .get('articlesection/find', {params: {code: this.code, scope: 'name,url,articles'}})
        .then(response => {

          let data = response.data;

          this.name = data.data.name;
          this.url = data.data.url;

          let articles = [];
          $.each(data.articles, function (key, val) {
            let domain = "https://off.radiokrakow.pl/api/"
            let domain_new = "https://off.radiokrakow.pl/"

            val.thumb = domain_new + val.thumb.substr(domain.length)
            val.block_thumb = domain_new + val.block_thumb.substr(domain.length)

            articles.push(val)
          })

          this.articles = articles;

        })
        .catch(e => {
          console.log(e)
        })

  }
}
</script>

<style>



</style>