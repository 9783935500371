<template>
  <section class="article-list" v-if="articles && articles.length > 0">
    <div class="container">
      <div class="row mb-0">
        <div class="col s12">
          <div class="title-line mb-4">

            <h2>
              Ostatnie publikacje
            </h2>

          </div>
        </div>
      </div>
      <div class="article-user-section">
        <div class="row">
          <div class="col s12 m6 l3" v-for="article in articles">
            <ArticleBlock :article="article"/>

          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import axios from "@/axios";

import ArticleBlock from "@/views/article/ArticleBlock";
import {Carousel, Navigation, Slide} from "vue3-carousel";

export default {
  name: "ArticleUserList",
  props: ['id_user'],
  components: {
    ArticleBlock,
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      articles: null,
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
        // 1024 and up
        1224: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
      },
    }
  },

  created: function () {


    axios
        .get('article/get', {
          params: {
            id_user_only: this.id_user,
            is_show: true,
            count: 4,
            scope: 'title,block_thumb,thumb,date,url'
          }
        })
        .then(response => {

          let domain = "https://off.radiokrakow.pl/api/"
          let domain_new = "https://off.radiokrakow.pl/"

          let data = response.data;

          let articles = [];
          $.each(data.data, function (key, val) {


            val.block_thumb = domain_new + val.block_thumb.substr(domain.length)
            val.thumb = domain_new + val.thumb.substr(domain.length)

            articles.push(val)

          })

          this.articles = articles;

        })
        .catch(e => {
          console.log(e)
        })

  }
}
</script>

<style>


.article-user-section .carousel {
  height: auto;
  overflow: visible !important;
}

.article-user-section .carousel__viewport {
  overflow-y: visible !important;

}

.article-user-section .carousel__slide {
  text-align: left;
  padding: 0 12px;
}

.article-user-section .carousel__slide .family-block {
  width: 100%;
}

.article-user-section .carousel__prev,
.article-user-section .carousel__next {

  top: -43px !important;
  background-color: transparent;
  position: absolute;
  color: #fff;

  display: block;
  left: auto;

  /*background-color: red!important;*/

  z-index: 200000;
}

.article-user-section .carousel__prev {
  right: 45px !important;
}

.article-user-section .carousel__next {
  right: 25px !important;
}

</style>
