<template>

  <ul class="left list-line">

    <li v-for="pos in poss" :key="pos.name">
      <router-link :to="{path: '/'+ pos.url}">
        {{ pos.name }}
      </router-link>
    </li>
  </ul>

</template>

<script>
import axios from "@/axios";

export default {
  name: "MenuBottom",
  data() {
    return {
      is_loading: false,
      poss: null,
      count: 0
    }
  },

  created: function () {
    this.is_loading = true;

    axios
        .get('sitemenu/poss', {params: {code: 'top'}})
        .then(response => {

          let data = response.data;

          this.poss = data.data;
          this.count = this.poss.length;

        })
        .catch(e => {
          console.log(e)
        })

  }
}
</script>

<style scoped>

</style>