<template>

    <ul class="hide-on-med-and-down">

      <li v-for="pos in poss_first" :key="pos.name">
        <router-link :to="'/'+pos.url">
          {{ pos.name }}
        </router-link>
      </li>

      <li class="show-more">
        <a href="" style="padding-bottom: 30px;">
          ...
        </a>

        <ul>

          <li v-for="pos in poss_second" :key="pos.name">
            <router-link :to="'/'+pos.url">
              {{ pos.name }}
            </router-link>
          </li>


        </ul>
      </li>

    </ul>



</template>

<script>
import axios from "@/axios";

export default {
  name: "MenuTop",
  data() {
    return {
      is_loading: false,
      poss: null,
      poss_first: null,
      poss_second: null,
      poss_first_count: 4,
      count: 0
    }
  },

  created: function () {
    this.is_loading = true;

    axios
        .get('sitemenu/poss', {params: {code: 'top'}})
        .then(response => {

          let data = response.data;

          this.poss = data.data;
          this.count = this.poss.length;

          this.poss_first = this.poss .slice(0, this.poss_first_count);
          this.poss_second = this.poss .slice( this.poss_first_count);



          console.log(this.poss)

        })
        .catch(e => {
          console.log(e)
        })

  }
}
</script>

<style scoped>

</style>