<template>

  <div class="right search-click" :class="{ 'search-click-open': is_show }">
    <i class="search-click-icon material-icons" v-on:click="openClick">search</i>
    <form class="header-search" :class="{ 'header-search-open': is_show }" v-on:submit="search">
      <input type="text" class="browser-default header-search__input" name="search_text" v-model="text"
             ref="text_input">
      <button type="submit" class="header-search__button">
        szukaj
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  props: {
    msg: String
  },
  data() {
    return {
      is_show: false,
      text: null
    }
  },
  methods: {
    search(e) {
      e.preventDefault();

      this.$router.push({name: 'Search', params: {text: this.text}})
    },
    openClick: function (e) {

      e.preventDefault();

      if (this.is_show)
        this.close();
      else
        this.open();

    },
    open: function () {
      this.is_show = true;
      this.$refs.text_input.focus();
    },
    close: function () {
      this.is_show = false;

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.search_bar {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  z-index: 1120;
  background-color: rgba(0, 0, 0, 0.95);
  /*background-color: rgba(255, 255, 255, 0.95);*/
  /*display: none;*/

  @media screen and (max-width: 768px) {


      display: none;
  }
}

.search_bar__grid {
  width: 100%;
  max-width: 1000px;

  margin: 0;
  padding: 0;
  display: inline-block;

  position: absolute;

  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.search_bar__item {

  margin: 0 0 60px;
  padding: 0;
  display: inline-block;

  width: 33%;

  flex-direction: column;

  text-align: center;
}

.search_bar__icon {
  width: 120px;
  height: 120px;

  text-align: center;
  line-height: 118px !important;

  font-size: 3.55rem !important;

  border-radius: 50%;


  color: #000;
  background-color: #fafafa;
  display: flex;

  margin-bottom: 18px;
}

.search_bar__name {
  display: block;


  margin: 0 auto;
  text-align: center;

  color: #fff;
}


.search_bar .close-x {
  top: 15px;
}

.search_bar .close-x:before,
.search_bar .close-x:after {

  background: #fff;
}

@media screen and (max-width: 768px) {


  & {
    display: none;
  }

  .search_bar__item {
    width: 100%;
  }
}
</style>
