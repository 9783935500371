import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "./assets/css/materialize.min.css"
import "./assets/css/mod.css"
import "./assets/css/model.css"
import "./assets/css/style.css"

import "./assets/js/fn.js"
import "./assets/js/materialize.js"
import './registerServiceWorker'


const isSSR = typeof window === 'undefined';

window.$ = window.jQuery = require('jquery');

let vue = createApp(App)
    .use(store)
    .use(router)
    .mount('#app');
