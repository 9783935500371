<template>
  <main class="home">

    <SliderOwn/>

<!--    <ArticleSection code="main"/>-->
    <ArticleSection code="news"/>

    <ArticleFamilySection/>

    <SectionInfo code="main"/>

  </main>
</template>

<script>
import SliderOwn from "@/views/home/SliderOwn";
import ArticleSection from "@/views/article/ArticleSection";
import ArticleFamilySection from "@/views/article/FamilySection";
import SectionInfo from "@/views/home/SectionInfo";

export default {
  name: 'Home',
  components: {SectionInfo, ArticleFamilySection, ArticleSection, SliderOwn},
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
