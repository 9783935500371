<template>
  <main class="contact">

    <div class="container">
      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Off Radio Kraków" class="breadcrumb">
            Off Radio Kraków
          </router-link>

          <router-link to="/kontakt" title="Kontakt" class="breadcrumb">
            Kontakt
          </router-link>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col s12">
          <h1 class="big-title">Kontakt</h1>
        </div>
      </div>
      <SiteText code="kontakt-inside"/>
    </div>
    <br><br><br>
    <SectionInfo code="contact"/>

  </main>
</template>

<script>

import SectionInfo from "@/views/home/SectionInfo";
import SiteText from "../components/SiteText";

export default {
  name: 'Contact',
  components: {SiteText, SectionInfo},
  props: {
    msg: String
  },
  created() {
    document.title = "Kontakt";

    document.querySelector('meta[property="og:title"]').content = 'Kontakt'
    document.querySelector('meta[property="og:description"]').content = 'Kontakt'
    document.querySelector('meta[property="og:image"]').content = 'Kontakt'

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
