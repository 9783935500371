<template>
  <div v-html="text"></div>
</template>

<script>
import axios from "@/axios";

export default {
  name: 'SiteText',
  props: {
    code: String
  },
  data() {
    return {
      text: null,
    }
  },
  created() {

    axios
        .get('site/find', {
          params: {
            url: this.code,
            scope: 'text'
          }
        })
        .then(response => {

          this.text = response.data.data.text;

        });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
