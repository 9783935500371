<template>

  <div class="roadmap-now" v-if="is_show">
    <span class="roadmap-now__dot"></span>
    <a class="roadmap-now__family">{{ family }}</a>
    <span class="roadmap-now__user">{{ user_name }}</span>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "RoadmapNow",
  data() {
    return {
      is_show: true,
      family: null,
      family_link: null,
      user: null,
      user_name: null,
    };
  },
  methods: {
    refresh: function () {

      let self = this;

      axios
          .get('articleroadmap/now')
          .then(response => {

                let data = response.data;

                let now = data.now;
                this.family = now.name;
                this.family_link = now.obj ? now.obj.slug : null;
                this.user_name = "";

                if (now.users.length > 0) {
                  let user_name = [];
                  $.each(now.users, function (key, val) {
                    user_name.push(val.name)
                  });
                  this.user_name = user_name.join(",");

                }

                setTimeout(function () {
                  self.refresh();
                }, 60000);

              }
          );


    }
  },
  created() {
    this.refresh();
  }
}
</script>

<style scoped>

</style>