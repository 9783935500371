<template>
  <section class="family-list" v-if="familys && familys.length > 0">
    <div class="container">
      <div class="row mb-0">
      <div class="col s12">
        <div class="title-line mb-4">

          <h2>
            Prowadzone audycje
          </h2>

        </div>
      </div>
      </div>
      <div class="row">
        <div class="col s12 m6 l4 xl3" v-for="family in familys">
          <FamilyBlock :family="family"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "@/axios";

import FamilyBlock from "@/views/article/FamilyBlock";

export default {
  name: "FamilyUserList",
  props: ['id_user'],
  components: {
    FamilyBlock,
  },
  data() {
    return {
      familys: null
    }
  },

  created: function () {


    axios
        .get('articlefamily/get', {params: {id_user: this.id_user, type: 'broadcast', is_show: true,count: 4, scope: 'title,url,thumb,users', users_scope: 'firstname,lastname,name,avatar_url,url_full'}})
        .then(response => {

          let data = response.data;

          let familys = [];
          $.each(data.data, function (key, val) {

            let domain = "https://off.radiokrakow.pl/api/"
            let domain_new = "https://off.radiokrakow.pl/"

            val.thumb = domain_new + val.thumb.substr(domain.length)

            familys.push(val)

          })

          this.familys = familys;

        })
        .catch(e => {
          console.log(e)
        })

  }
}
</script>

<style>



</style>