<template>
  <div class="article-block  article-block-bottom-mid">
    <router-link :to="'/'+article.url" class="article-block__thumb"
                 :style="{ backgroundImage: `url(${article.block_thumb})` }" tag="div"></router-link>
    <div class="article-block__info">
      <span class="article-block__date">{{ article.weekday }}, {{ article.date.substr(0, 10) }}
 </span>
      <h3 class="article-block__title">
        <router-link :to="'/'+article.url">{{ article.title }}</router-link>
      </h3>
    </div>

  </div>
</template>

<script>
export default {
  name: "ArticleBlock",
  props: ['article'],
  data() {
    return {
      weekdays: {
        0: 'Niedziela',
        1: 'Poniedziałek',
        2: 'Wtorek',
        3: 'Środa',
        4: 'Czwartek',
        5: 'Piątek',
        6: 'Sobota',
      }
    }
  },
  created: function () {
    this.article.weekday = this.weekDay();
  },
  methods: {
    weekDay: function () {

      var Xmas95 = new Date(this.article.date);
      var weekday = Xmas95.getDay();

      return this.weekdays[weekday]

    }
  }
}
</script>

<style scoped>

</style>