<template>
  <main class="container">

      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Off Radio Kraków" class="breadcrumb">
            Off Radio Kraków
          </router-link>

          <router-link to="/news" title="Wyszukiwanie" class="breadcrumb">
            Wyszukiwanie
          </router-link>
        </div>
      </div>

    <div class="row">
      <div class="col s12">
        <h1 class="big-title">Wyszukiwanie</h1>
      </div>
    </div>
    <div class="row">
      <div class="col s12">

        <div class="card white" v-if="count === 0">
          <div class="card-content black-text">
            <p>Brak</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col s12 m6 xl4" v-for="article in articles">
        <ArticleBlock :article="article"/>
      </div>
    </div>
    <div class="row">
      <div class="col s12">
<Pagination :page="page" :count="count"/>
      </div>
    </div>
    <SectionInfo code="search"/>
  </main>
</template>

<script>
import axios from "@/axios";
import ArticleBlock from "@/views/article/ArticleBlock";
import Pagination from "@/components/Pagination";
import SectionInfo from "@/views/home/SectionInfo";

export default {
  name: "Search",
  components: {SectionInfo, Pagination, ArticleBlock},
  props: ['text'],
  data() {
    return {
      articles: null,
      count: 0,
      page: 1,
    }
  },
  methods: {
    refresh: function (){

      axios
          .get('index/search', {params: { scope: 'title,thumb,url,date','search_text': this.text, 'page': this.page,'count': 20, 'is_show': true}})
          .then(response => {

            let data = response.data;

            let domain = "https://off.radiokrakow.pl/api/"
            let domain_new = "https://off.radiokrakow.pl/"

            $.each(data.data, function (key, val) {
              val.thumb = domain_new + val.thumb.substr(domain.length)
            });

            this.count = data.count;
            this.articles = data.data;
          })

    }
  },
  watch: {

    '$route.params.text': {
      handler: function (text) {

          this.refresh();


      }
    }
  },
  created() {
    this.refresh()
  }

}
</script>

<style scoped>

</style>