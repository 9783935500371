<template>

</template>

<script>
import axios from "@/axios";

export default {
  name: "SectionInfo",
  props: [
      'code'
  ],created() {
    let code = this.code;


    axios.get('sitesection/find',{params:{'type':code}}).then(response => {

      let data = response.data.data;

      let title = data.name;
      if(data.meta_title)
        title = data.meta_title;

      this.$root.metaSet(title,data.meta_description)



    });

  }
}
</script>

<style scoped>

</style>