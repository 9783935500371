<template>
  <section class="article-family-section">


    <div class="container-fluid">
      <div class="row">
        <div class="col s12">
          <div class="title-line mb-3">
            <h2>
              <router-link to="/audycje" class="brand-logo">
                Audycje
              </router-link>
            </h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s12 m6 l4 xl3" v-for="family in familys">
          <ArticleFamilyBlock :family="family"/>

        </div>
      </div>

    </div>
    <div class="clearfix"></div>
    <br><br><br>
    <div class="clearfix"></div>
  </section>
</template>

<script>
import axios from "@/axios";
import ArticleFamilyBlock from "@/views/article/FamilyBlock";

export default {
  name: "ArticleFamilySection",
  components: {
    ArticleFamilyBlock,

  },
  data() {
    return {
      familys: [],
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
        // 1024 and up
        1600: {
          itemsToShow: 6,
          snapAlign: 'start',
        },
      },
    }
  },

  created: function () {

    axios
        .get('articlefamily/get', {
          params: {
            page: 1,
            count: 4,
            sort: 'title',
            is_show: 'yes',
            scope: 'title,url,thumb,roadmap_str,users',

            users_scope: 'avatar_url,url_full,name,firstname,lastname,position'
          }
        })
        .then(response => {

          let result_data = response.data.data;

          let familys = [];

          $.each(result_data, function (key, val) {
            let domain = "https://off.radiokrakow.pl/api/"
            let domain_new = "https://off.radiokrakow.pl/"

            val.thumb = domain_new + val.thumb.substr(domain.length)

            familys.push(val)

          })

          this.familys = [...familys];

        })
        .catch(e => {
          console.log(e)
        })

  }
}
</script>

<style>
.article-family-section .carousel {
  height: auto;
  overflow: visible !important;
}

.article-family-section .carousel__viewport {
  overflow-y: visible !important;

}

.article-family-section .carousel__slide {
  text-align: left;
  padding: 0 12px;
}

.article-family-section .carousel__slide .family-block {
  width: 100%;
}

.article-family-section .carousel__prev,
.article-family-section .carousel__next {

  top: -43px !important;
  background-color: transparent;
  position: absolute;
  color: #fff;

  display: block;
  left: auto;

  /*background-color: red!important;*/

  z-index: 200000;
}

.article-family-section .carousel__prev {
  right: 45px !important;
}

.article-family-section .carousel__next {
  right: 25px !important;
}
</style>
