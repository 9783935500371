<template>

  <ul class="user-list user-list-min">
    <li class="user-list__item" v-for="user in users">
      <router-link :to=" '/'+ user.url_full" class="user-list__link">
        <div class="user-list__thumb" :style="{ backgroundImage: `url(${user.avatar_url})` }"></div>

        <h4 class="user-list__name" v-if="user.account_type == 'private' || user.firstname">{{user.firstname}} {{user.lastname}}</h4>
        <h4 class="user-list__name" v-else-if="user.account_type == 'company' || user.name != ''">{{user.name}}</h4>
      </router-link>

    </li>
  </ul>
</template>

<script>
export default {
  name: "UserSmallList",
  props: ['users'],
  created() {

    let domain = "https://off.radiokrakow.pl/api/"
    let domain_new = "https://off.radiokrakow.pl/"

    $.each(this.users, function (key, val) {
      val.avatar_url = domain_new + val.avatar_url.substr(domain.length)
    })


  }
}
</script>

<style scoped>

</style>