<template>
  <div class="family-block article-block-half">

    <ul class="options">
      <li class="options__item" style="display: none;">
        <ShareButton class="options__link" :url="family.url" :title="family.title" :thumb="family.thumb"/>
      </li>
    </ul>

    <router-link :to="'/'+family.url" class="family-block__bg"  :style="{ backgroundImage: `url(${family.thumb})` }"></router-link>

    <div class="family-block__info">
      <h2 class="family-block__title">
        <router-link :to="'/'+family.url">
          {{family.title}}
        </router-link>
      </h2>

      <UserSmallList :users="family.users"/>


    </div>


  </div>
</template>

<script>
import UserSmallList from "@/views/user/SmallList";
import ShareButton from "@/components/ShareButton";
export default {
  name: "ArticleFamilyBlock",
  components: {ShareButton, UserSmallList},
  props: ['family'],
  created: function (){

  }
}
</script>

<style scoped>

</style>