<template>
  <main >

    <div class="container">
      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Off Radio Kraków" class="breadcrumb">
            Off Radio Kraków
          </router-link>

          <router-link to="/news" title="Artykuły" class="breadcrumb">
            Artykuły
          </router-link>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col s12">
          <h1 class="big-title">Artykuły</h1>
        </div>
      </div>

      <div class="row">
        <div class="col s12 m6 xl4" v-for="article in articles">
          <ArticleBlock :article="article"/>
        </div>
      </div>

      <div class="row">
        <div class="col s12">

          <Pagination :page="page" :count="count"/>
        </div>
      </div>

    </div>

    <SectionInfo code="articlelist"/>
  </main>
</template>

<script>
import axios from "@/axios";
import ArticleBlock from "@/views/article/ArticleBlock";
import Pagination from "@/components/Pagination";
import SectionInfo from "@/views/home/SectionInfo";

export default {
  name: "ArticleList",
  components: {
    ArticleBlock,
    SectionInfo,
    Pagination,
  },
  data(){
    return {
      'page': 1,
      'count': null,
      'articles': null,
    }
  },
  methods: {
      refresh: function(){

        axios
            .get('article/get', {params: { scope: 'title,block_thumb,thumb,url,date', 'type':'article','page': this.page,'count': 21, 'is_show': true}})
            .then(response => {

              let data = response.data;

              let domain = "https://off.radiokrakow.pl/api/"
              let domain_new = "https://off.radiokrakow.pl/"

              $.each(data.data, function (key, val) {
                val.block_thumb = domain_new + val.block_thumb.substr(domain.length)
                val.thumb = domain_new + val.thumb.substr(domain.length)
              });

              this.count = data.count;
              this.articles = data.data;
            })
      }
  },
  watch: {
    page: function (val) {
      this.refresh();
    },
  },
  created() {
    this.refresh();
  }
}
</script>

<style scoped>


</style>
