<template>


  <div class="family-line" v-if="article">
    <router-link :to="'/'+article.url" class="family-line__thumb" :style="{ backgroundImage: `url(${article.block_thumb})` }"></router-link>
    <div class="family-line__info">
      <h3 class="family-line__title">
        <router-link :to="'/'+article.url">
          {{ article.title }}
        </router-link>
      </h3>
      <ul class="meta">
        <li class="meta__item" v-if="article.audio_time && article.audio_time !== '00:00:00'">
          <i class="material-icons">access_time</i>
          {{ article.audio_time }}
        </li>

        <li class="meta__item">
          <i class="material-icons">calendar_today</i>
          {{ article.date }}
        </li>

        <li class="meta__item">
          <ul class="tag-list">
            <li class="tag-list__item" v-for="label in article.labels">{{ label.name }}</li>
          </ul>
        </li>
      </ul>

      <br v-if="article.users && article.users.lenght == 0">

      <UserSmallList :users="article.users" v-if="article.users && article.users.lenght == 0"/>

      <p class="family-line__text" v-html="article.text" v-if="article.text && article.text.lenght > 4"></p>

    </div>
    <ul class="options">

      <li class="options__item" v-if="article.audio">

        <a :href="article.audio_url"
           :data-name="article.name"
           :data-thumb="article.thumb"
           :data-url="article.audio_url"
           :id="'audio'+article.id"
           class="audio-btn"

           v-on:click="playAudio"
        >
          <i class="material-icons">play_arrow</i>
        </a>

      </li>

<!--      <li class="options__item options__item-text-btn family-line__text-btn" v-if="article.text"></li>-->
    </ul>
  </div>

</template>

<script>
import UserSmallList from "@/views/user/SmallList";
import axios from "@/axios";

export default {
  name: "PodcastLine",
  components: {UserSmallList},
  props: {'article': null},

  methods: {

    playAudio: function (e){

      e.preventDefault();

      let audioPlayer = this.$root.$refs.audioPlayer;
      let article = this.article;

      audioPlayer.set(article.audio_url, article.top_name, article.top_thumb_url);
      audioPlayer.play();
      audioPlayer.show();

    },
  },

  data() {
    return {}
  },
  created() {



    // let domain = "audycje"
    // let domain_new = "podcasty"


    // this.article.url = domain_new + this.article.url.substr(domain.length)

  }

}
</script>

<style scoped>

</style>