<template>
  <a class="share-btn"
     :data-url="url"
     :data-title="title"
     :data-text="text"
     :data-thumb="thumb"
     v-on:click="click"
  ><i class="material-icons">share</i></a>

</template>

<script>
export default {
  name: 'ShareButton',
  props: {
    url: String,
    title: String,
    text: String,
    thumb: String,
  },
  data() {
    return {
      is_show: false
    }
  },
  methods: {
    click:function (){

      let shareWindow = this.$root.$refs.shareWindow;

      shareWindow.open(this.url,this.title,this.text,this.thumb)

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



</style>
