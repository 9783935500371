<template>

  <div class="playersmall" v-if="is_show === 'yes'">

    <div class="container position-relative">
      <div class="row mb-0">
        <div class="col s12">

          <div id="audio" style="position: relative">

            <div class="playersmall-thumb" id="audio__thumb" :style="{ backgroundImage: `url(${thumb})` }"></div>

            <div class="playersmall-progress" id="audio__progress" v-on:mousedown="progressMouse"
                 v-on:mouseup="progressMouse" v-on:mousemove="progressMouseMove">
              <div class="playersmall-progress-track" id="audio__progress-track"></div>
              <div class="playersmall-progress-load" id="audio__progress-load"
                   :style="{ width: `${progres_position*100}%` }"></div>
            </div>

            <a class="playersmall-info" id="audio__volume-show" v-on:click="volumeShow">
              <i class="material-icons">volume_up</i>
            </a>

            <div class="playersmall-time" id="audio__time">
              {{ progres_time }}
            </div>
            <div class="playersmall-volume">
              <div id="audio__volume" class="audio__volume" v-if="volume_show === 'yes'" v-on:mousedown="volumeMouse"
                   v-on:mouseup="volumeMouse" v-on:mousemove="volumeMouseMove">
                <div id="audio__volume-track" class="audio__volume-track" v-bind="volume_track"></div>
                <div id="audio__volume-position" class="audio__volume-position"
                     :style="{ width: `${volume_pos*100}%` }"></div>
              </div>
            </div>

            <a class="playersmall-playpause" id="audio__play" v-on:click="playClick"
               v-bind:class="{played: is_play == 'yes'}">
              <i class="material-icons play">play_arrow</i>
              <i class="material-icons pause">pause</i>
            </a>
            <div class="playersmall-time-full" id="audio__time-full">{{ audio_time }}</div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import "@/assets/js/player-audio";
export default {
  name: "AudioPlayer",
  data() {
    return {
      audio: new Audio(),
      audio_time: null,

      url: null,
      name: null,
      thumb: null,

      is_play: 'no',
      is_show: 'no',

      volume_show: 'no',
      volume_track: null,
      volume_pos: 1,

      progres_position: null,
      progres_time: null,
    }
  },
  methods: {

    volumeShow: function () {


      if (this.volume_show === 'yes') {
        this.volume_show = 'no';
      } else if (this.volume_show === 'no') {
        this.volume_show = 'yes';
      }

    },
    volumeMouse: function (e) {

      let width = e.target.offsetWidth;
      let position = e.layerX;

      let volume_set = (position / width);

      this.volume_pos = volume_set;

      this.audio.volume = volume_set;

    },
    volumeMouseMove: function (e) {
      if (e.buttons == 1) {
        this.volumeMouse(e)
      }

    },
    progressSet: function (audio, progress_set, progress_position) {

      progress_set = progress_set < 1 ? progress_set : audio.progress;
      progress_set = progress_set < 0 ? audio.progress : progress_set;

      progress_set = progress_set * 100;
      progress_set = parseInt(progress_set);
      progress_set = parseFloat(progress_set / 100);


      this.progres_position = progress_set;

      if (audio.currentTime != progress_set * audio.duration)
        audio.currentTime = progress_set * audio.duration;


    },

    progressMouse: function (e) {

      let audio = this.audio

      let width = e.target.offsetWidth;
      let position = e.layerX;

      let progress_set = (position / width);

      this.progressSet(audio, progress_set)

    },
    progressMouseMove: function (e) {
      if (e.buttons == 1) {
        this.progressMouse(e)
      }

    },

    set(url, name, thumb) {

      this.url = url;
      this.name = name;
      this.thumb = thumb;

      this.audio.src = url;
      this.audio.currentTime = 0;
    },

    playClick: function () {

      if (this.is_play === 'yes')
        this.pause();

      else if (this.is_play === 'no')
        this.play();

    },

    play: function () {

      this.$root.$refs.radioPlayer.stop()
      this.$root.$refs.radioPlayer.hide()

      this.audio.play();
      this.is_play = 'yes';

    },

    pause: function () {

      if (this.is_play == 'yes') {

        this.audio.pause();
        this.is_play = 'no';
      }

    },

    stop: function () {

      this.pause();

      this.audio.src = this.audio.src;


    },

    show: function () {

      this.is_show = 'yes';

    },

    hide: function () {

      this.is_show = 'no';

    }
  },
  created() {

    let self = this;

    this.audio.addEventListener("timeupdate", function (e) {

      let audio = e.target

      // console.log(audio.duration, audio.currentTime)

      let load = audio.currentTime / audio.duration;

      self.progres_position = load;

      console.log(audio.duration);

      let s = parseInt(audio.currentTime % 60);
      let m = parseInt((audio.currentTime / 60) % 60);
      let h = parseInt((audio.currentTime / 3600) % 3600);

      let s2 = parseInt(audio.duration % 60);
      let m2 = parseInt((audio.duration / 60) % 60);
      let h2 = parseInt((audio.duration / 3600) % 3600);

      console.log(s2,m2,h2)

      if (!isNaN(m2)) {

        self.progres_time = (h > 0 ? h + ':' : '') + (m < 10 ? '0' : '') + m + ':' + (s < 10 ? '0' : '') + s;
        self.audio_time = (h2 > 0 ? h2 + ':' : '') + (m2 < 10 ? '0' : '') + m2 + ':' + (s2 < 10 ? '0' : '') + s2;
      }

    }, false);
    this.audio.addEventListener("ended", function (e) {

      self.stop();

    });

  }
}
</script>

<style scoped>

</style>