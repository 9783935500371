<template>
  <Header/>
  <router-view/>

  <ShareWindow ref="shareWindow"/>
  <RadioPlayer ref="radioPlayer"/>
  <AudioPlayer ref="audioPlayer"/>

  <Footer/>

  <Loader/>

  <div class="sidenav-overlay"></div>
  <MenuDynamic/>

</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import ShareWindow from "@/components/ShareWindow";
import RadioPlayer from "@/components/RadioPlayer";
import AudioPlayer from "@/components/AudioPlayer";
import Header from "@/layouts/Header";
import Footer from "@/layouts/Footer";
import Loader from "@/layouts/Loader";

import '@/assets/css/fontawesome.css';
import MenuDynamic from "@/layouts/MenuDynamic";

export default {
  name: 'App',
  components: {
    MenuDynamic,
    Loader,
    Header,
    Footer,
    AudioPlayer,
    RadioPlayer,
    ShareWindow,
    HelloWorld
  },
  methods: {
    metaSet: function (name, text, image = null) {
      document.title = name;
      document.querySelector('meta[property="og:title"]').content = name;
      document.querySelector('meta[property="og:description"]').content = text;
      document.querySelector('meta[property="og:image"]').content = image;


    }
  }
}
</script>

<style>

</style>
