<template>
  <section id="sliderOwn">
    <div class="row">

      <div class="col s12 p-0">


        <Carousel :items-to-show="1" :autoplay="15000">
          <Slide v-for="article in articles" :key="article.name">

              <div class="article-slide" style="background-image: url('{{article.thumb}}');">

                <img :src="article.thumb"/>

                <router-link class="article-slide__info" :to="'/'+article.url">
                  <h2 class="article-slide__title">
                    {{article.title}}
                  </h2>
                  <br>
                  <h2 class="article-slide__text" v-if="article.lead">{{article.lead}}</h2>

                </router-link>
            </div>

          </Slide>

          <template #addons>
            <Pagination />
          </template>
        </Carousel>

      </div>
    </div>

  </section>
</template>

<script>
import axios from "@/axios";
import { Carousel, Slide, Pagination} from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default {
  name: "SliderOwn",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      articles: null,
      name: null
    }
  },

  created: function () {

    axios
        .get('articlesection/find', {params: {code: 'slider', scope: 'name,url,articles'}})
        .then(response => {

          let data = response.data;

          this.name = data.data.name;

          let articles = [];
          $.each(data.articles, function (key, val){
            let domain = "https://off.radiokrakow.pl/api/"
            let domain_new = "https://off.radiokrakow.pl/"

            val.thumb = domain_new + val.thumb.substr(domain.length)

            articles.push(val)
          })

          this.articles = articles;
          
        })
        .catch(e => {
          console.log(e)
        })

  }
}
</script>

<style scoped>

.carousel {
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
  height: calc(100vh - 84px) !important;
}
.carousel__slide {
  width: 100% !important;
  height: calc(100vh - 84px) !important;
  /* max-height: 800px; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.carousel__slide img{

  position: absolute;
  left:-10000%; right: -10000%;
  top: -10000%; bottom: -10000%;
  margin: auto auto;
  min-width: 1000%;
  min-height: 1000%;
  -webkit-transform:scale(0.1);
  transform: scale(0.1);
}
.carousel__pagination{
  z-index: 1000;
  position: absolute;

  top: 0;
  width: 100%;

  text-align: center;


}
.carousel__pagination-button{
  width: 1em!important;
  height: 1em!important;
  margin: 13px 6px!important;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  /*border: 4px solid transparent;*/
  background-color: transparent!important;

  background-color: #fff;
  background-color: rgba(0,0,0,.5) !important;
  /* background-color: rgba(255,255,255,.5); */
  mix-blend-mode: difference;
  border-radius: 50%;
  overflow: hidden;
}
.carousel__pagination-button--active{
  background-color: #000!important;
  width: 1.5em!important;
  height: 1.5em!important;
  margin: 9px 3px!important;
}


.article-slide {
  width: 100%;
  height: calc(100vh - 84px);

  /*max-height: 800px;*/

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  position: relative;
}

.article-slide__info {

  position: absolute;
  left: 24px;
  bottom: 24px;
  padding: 36px;
  /*transform: translateX(-50%);*/
  text-align: left;
  /*background-color: #000;*/
  max-width: 100%;
  transition: .3s all;
}

.article-slide__date {

  background-color: #000;
  color: inherit;

  padding: 0 12px;

  text-align: right;
}

.article-slide__text {
  font-size: 16px;
  text-align: left;

  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 12px;
  background-color: #000;

  max-width: 500px;

  width: auto;
  display: inline-block;
}

.article-slide__title {
  font-size: 24px;
  font-weight: 700;
  /*background-color: #000;*/
  color: inherit;

  text-align: left;

  padding: 6px 12px 12px;
  margin: 0 0 12px;


  background-color: #000;

  width: auto;
  display: inline-block;

  width: auto;
  max-width: 500px;
}


.article-slide__info:hover .article-slide__title,
.article-slide__info:hover .article-slide__text {
  background-color: #fff;
  color: #000;
}

@media screen and (max-width: 768px) {

  .article-slide__info {
    left: 0;
    bottom: 0;
    transform: translateX(0%);
  }

  .article-slide__title {
    font-size: 16px;
  }

  .article-slide__text {
    display: none;
  }

}

@media screen and (min-width: 1025px) and (max-width: 1600px) {


  .article-slide__info {
    padding: 18px;
  }

}

</style>