<template>

  <ul class="pagination">
    <li class="waves-effect left" v-if="page_before" v-on:click="pageSub()">
      <i class="material-icons">chevron_left</i>
    </li>

    <li v-bind:class="{ active: page_one == page }" v-for="page_one in page_max" v-on:click="pageSet(page_one)">
      {{ page_one }}
    </li>

    <li class="waves-effect right" v-if="page_after" v-on:click="pageInc()">
      <i class="material-icons">chevron_right</i>
    </li>


  </ul>

</template>

<script>
export default {
  name: "Pagination",
  props: ['count', 'page'],
  data() {
    return {
      page_count: 20,
      page_max: null,
      page_before: false,
      page_after: false,
    }
  },
  methods: {
    pageSet: function (page) {

      console.log('Ustawiam stronę na: ', page)
      this.$parent.page = page;
    },
    pageInc: function (page) {
      this.$parent.page ++
    },
    pageSub: function (page) {
      this.$parent.page --
    },
    update: function () {

      this.page_max = Math.ceil(this.count / this.page_count);
      this.page_before = this.page > 1;
      this.page_after = this.page < this.page_max;

    }
  },
  watch: {
    page: function (val) {
      this.update();
    },
    count: function (val) {
      this.update();
    },
  }
}
</script>

<style scoped>

</style>