<template>

  <div class="sidenav" id="mobile-demo">
    <img src="@/assets/images/logo.png" alt="Off Radio Kraków" style="margin: 32px 48px 0; width: calc(100% - 96px )">
    <ul>
      <li v-for="pos in poss" :key="pos.name">
        <router-link :to="{path: '/'+ pos.url}" v-on:click="close">
          {{ pos.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "@/axios";
export default {
  name: "MenuDynamic",
  data() {
    return {
      poss: null,
      count: 0,
      is_open: false,
    }
  },
  methods: {
    open: function () {

      this.is_open = true;

      $('.sidenav').css('transform', 'translateX(0)');
      $('.sidenav-overlay').css('display', 'block');
      $('.sidenav-overlay').css('opacity', 1);

    },
    close: function () {

      this.is_open = false;

      $('.sidenav').css('transform', 'translateX(-105%)');
      $('.sidenav-overlay').css('display', 'none');
      $('.sidenav-overlay').css('opacity', 0);
    },
  },

  created: function () {
    let self = this;

    axios
        .get('sitemenu/poss', {params: {code: 'top'}})
        .then(response => {

          let data = response.data;

          this.poss = data.data;
          this.count = this.poss.length;

        })
        .catch(e => {
          console.log(e)
        });

    $('.sidenav-trigger').click(function (e){
      e.preventDefault();
      console.log(1);

      if(self.is_open == false)
        self.open();
      else if( self.is_open == true )
        self.close();

    })

    $('.sidenav-overlay').click(function (e){
      self.close();
    });




  }
}
</script>

<style scoped>

</style>