<template>

  <main class="contact">

    <div class="container">
      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Off Radio Kraków" class="breadcrumb">
            Off Radio Kraków
          </router-link>

          <router-link to="/audycje" title="Audycje" class="breadcrumb">
            Audycje
          </router-link>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col s12">
          <h1 class="big-title">Audycje</h1>
        </div>
      </div>


      <div class="row">

        <div class="col s12 m6 l4 xl4" v-for="family in familys">
          <ArticleFamilyBlock :family="family"/>
        </div>
      </div>

    </div>
    <SectionInfo code="broadcastfamilylist"/>
  </main>
</template>

<script>
import ArticleFamilyBlock from "@/views/article/FamilyBlock";
import axios from "@/axios";
import SectionInfo from "@/views/home/SectionInfo";

export default {
  name: "FamilyList",
  components: {SectionInfo, ArticleFamilyBlock},
  data() {
    return {
      familys: null,
    }
  },
  created() {

    let headers = {
      'objs_count': 999999
    }

    axios
        .get('articlefamily/get', {
          params: {
            sort: 'title',
            status: 'live',
            count: 999999,
            scope: 'title,url,thumb,users',
            users_scope: 'name,account_type,firstname,lastname,avatar_url,url_full'
          }, headers: headers

        })
        .then(response => {

          let familys = [];
          $.each(response.data.data, function (key, val) {
            let domain = "https://off.radiokrakow.pl/api/"
            let domain_new = "https://off.radiokrakow.pl/"

            val.thumb = domain_new + val.thumb.substr(domain.length)


            familys.push(val)
          })

          this.familys = familys;

        })
        .catch(e => {
          console.log(e)
        })

  }

}
</script>

<style scoped>

</style>