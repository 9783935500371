<template>

    <div class="people-line">
      <div class="people-line__thumb"
           :style="{ backgroundImage: `url(${user.avatar_url})` }"></div>
      <router-link :to="'/'+user.url_full"
         class="people-line__name">
        {{user.firstname}}
        {{user.lastname}}
      </router-link>
      <br>
      <span class="people-line__position">{{user.position}}</span>
    </div>
</template>

<script>
export default {
  props: ['user'],
  name: "UserLine"
}
</script>

<style scoped>

</style>