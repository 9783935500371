<template>

  <ul class="menu-list m-0">
    <li class="menu-list__item" v-for="pos in poss" :key="pos.name">
      <router-link :to="{path: '/'+ pos.url}" class="menu-list__link" :class="{ 'menu-list__link-active': (pos.url == slug) }">
        {{ pos.name }}
      </router-link>
    </li>
  </ul>
</template>

<script>
import axios from "@/axios";
export default {
  name: "MenuSite",
  data() {
    return {
      poss: null,
      slug: null,
      count: 0,
    }
  },

  created: function () {
    let self = this;

    this.slug = this.$route.params.slug

    axios
        .get('sitemenu/poss', {params: {code: 'site'}})
        .then(response => {

          let data = response.data;

          this.poss = data.data;
          this.count = this.poss.length;

        })
        .catch(e => {
          console.log(e)
        });
  },
  watch: {

    '$route.params.slug': {
      handler: function (slug) {

        this.slug = slug;
      }
    }
  }
}
</script>

<style scoped>

</style>