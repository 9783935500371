<template>
  <div class="share_window" v-show="is_show">
    <button class="share_window__close close-x" v-on:click="close"></button>
    <div class="container">
      <div class="row">
        <div class="col s12">

          <ul class="share_window__grid">
            <li class="share_window__item">
              <a :href="'mailto:?body='+name+':'+url" class="share_window__link share_window__mail">
                <i class="share_window__icon material-icons">mail</i>
                <span class="share_window__name">Wyślij mail</span>
              </a>
            </li>
            <li class="share_window__item">
              <a :href="url" class="share_window__link share_window__copy" v-on:click="copyToUpload" >
                <i class="share_window__icon material-icons">
                  content_copy
                </i>
                <span class="share_window__name">Kopiuj link</span>
              </a>
            </li>
            <li class="share_window__item">
              <a :href="'https://www.facebook.com/sharer/sharer.php?u='+url+'&title='+name+'&description='+quote+'&picture='+image"
                 class="share_window__link share_window__facebook" target="_blank">
                <i class="share_window__icon fab fa-facebook-f"></i>
                <span class="share_window__name">Facebook</span>
              </a>
            </li>

          </ul>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: 'ShareWindow',
  props: {
    msg: String
  },
  data() {
    return {
      is_show: false,
      url: null,
      name: null,
      description: null,
      image: null,
    }
  },
  methods: {
    open: function (url, name = null, description = null, image = null) {
      let getUrl = window.location;
      // let baseUrl = getUrl.protocol + "//" + getUrl.host + "/";
      let baseUrl = 'https://off.radiokrakow.pl/';

      this.is_show = true;

      this.url = baseUrl + url
      this.name = name
      this.description = description
      this.image = image
    },
    copyToUpload: function(e){

      e.preventDefault();


      let textArea = document.createElement("textarea");
      textArea.value = this.url;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        let successful = document.execCommand('copy');
        let msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);

    },
    close: function () {
      this.is_show = false;

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.share_window {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  z-index: 1120;
  background-color: rgba(0, 0, 0, 0.95);
  /*background-color: rgba(255, 255, 255, 0.95);*/
  /*display: none;*/
}

.share_window__grid {
  width: 100%;
  max-width: 1000px;

  margin: 0;
  padding: 0;
  display: inline-block;

  position: absolute;

  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.share_window__item {

  margin: 0 0 60px;
  padding: 0;
  display: inline-block;

  width: 33%;

  flex-direction: column;

  text-align: center;
}

.share_window__icon {
  width: 120px;
  height: 120px;

  text-align: center;
  line-height: 118px !important;

  font-size: 3.55rem !important;

  border-radius: 50%;


  color: #000;
  background-color: #fafafa;


  margin-bottom: 18px;
}

.share_window__name {
  display: block;


  margin: 0 auto;
  text-align: center;

  color: #fff;
}


.share_window .close-x {
  top: 15px;
}

.share_window .close-x:before,
.share_window .close-x:after {

  background: #fff;
}

@media screen and (max-width: 768px) {

  .share_window__item {
    width: 100%;
  }
}
</style>
