const state = {
    token: localStorage.getItem('token')
};

const actions = {
    login({commit}, {data}) {

        let token = data.token;

        console.log(token);

        localStorage.setItem('token', token);
        commit('login', token);

    },
    logout({commit}) {
        localStorage.removeItem('token');
        commit('logout');
    },
};

const mutations = {
    login(state, token) {

        state.token = token;

    },
    logout(state) {

        state.token = null;

    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};