<template>

  <main>
    <div class="container">
      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Off Radio Kraków" class="breadcrumb">
            Off Radio Kraków
          </router-link>

          <router-link to="/ramowka" title="Ramówka" class="breadcrumb">
            Ramówka
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <h1 class="big-title">Ramówka</h1>
        </div>
      </div>

      <div class="menu-inside">{{menuTu}}</div>

      <div class="row mb-0">
        <div class="col s12">

          <div class="roadmap-week-select hide-on-large-only hide-on-extra-large-only"

               :class="{ 'roadmap-week-select-open': openWeek }"
          >
            <div class="roadmap-week-select__head"
                 @click="openWeek = !openWeek">
              {{ weekdays[date.getDay()] }}
            </div>
            <div class="roadmap-week-select__body">

              <div class="roadmap-week-select__day"
                   :data-day="weekdays[date_one.getDay()]"
                   :data-date="date_one"
                   v-for="date_one in date_arr"
                   v-on:click="dayclick(date_one)"
              >
                {{ weekdays[date_one.getDay()] }}
              </div>

            </div>
          </div>
          <div class="roadmap-week hide-on-med-and-down">


            <div class="roadmap-day" :class="{ 'roadmap-day-selected': (date.getDay() == date_one.getDay())  }"
                 :data-day="weekdays[date_one.getDay()]"
                 :data-date="date_one"
                 v-for="date_one in date_arr"
                 v-on:click="dayclick(date_one)"
            >
              {{ weekdays[date_one.getDay()] }}
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s12" v-for="roadmap in roadmaps">

          <div class="roadmap"  v-bind:class="{ roadmap_full: !roadmap.obj }">
            <router-link class="roadmap__thumb" v-if="roadmap.obj" :style="{ backgroundImage: `url(${roadmap.thumb})` }" tag="a"
               :to="'/audycje/'+roadmap.obj.slug"
               :href="roadmap.obj.url">
            </router-link>

            <div class="roadmap__info">
              <router-link class="roadmap__name" v-if="roadmap.obj" tag="a"
                 :to="'/audycje/'+roadmap.obj.slug">
                {{ roadmap.obj.name }}
              </router-link>
              <span class="roadmap__name" v-if="!roadmap.obj">
                {{ roadmap.name }}
            </span>

              <div class="roadmap-part" v-for="roadmap_pos in roadmap.poss">
                <div class="roadmap-part-thumb"
                     style="background-image: url('http://nrk.strefaportali.pl/data/article/family/36ad7069a1c29f96e480f43b604f1b9b3e142b58dcbfc0ea883bd118790d6bedwide705x396.jpg');">
                </div>
                <b class="roadmap-part-hour">
                  {{ roadmap_pos.start }} -
                  {{ roadmap_pos.end }}
                </b>
                <a class="roadmap-part-name"
                   href="<?php echo isset($pos['obj']) ? $this->urlObj($pos['obj']) : NULL ?>">
                  {{ roadmap_pos.obj }}
                </a>
              </div>
<div class="clearfix"></div>
              <div class="roadmap__users" v-for="user in roadmap.users">
                <router-link :to="'/'+user.url_full" class="user-item user-item-min">
                  <div class="user-item__thumb"
                       :style="{ backgroundImage: `url(${user.avatar_url})` }"></div>
                  <div class="user-item__name">
                    {{ user.firstname }} {{ user.lastname }}
                  </div>

                </router-link>
              </div>
            </div>
            <span class="roadmap__hour">
              {{ roadmap.start }}
              -
              {{ roadmap.end }}
            </span>
          </div>
        </div>
      </div>
    </div>
<SectionInfo code="roadmap"/>
  </main>
</template>

<script>
import axios from "@/axios";
import SectionInfo from "@/views/home/SectionInfo";

export default {
  name: "Roadmap",
  components: {SectionInfo},
  data() {
    return {
      roadmaps: null,
      openWeek: false,
      date: new Date(),
      date_arr: null,
      menuTu: null,
      weekdays: {
        0: 'Niedziela',
        1: 'Poniedziałek',
        2: 'Wtorek',
        3: 'Środa',
        4: 'Czwartek',
        5: 'Piątek',
        6: 'Sobota',
      }
    }
  },
  methods: {
    monday: function (fromDate) {

      // length of one day i milliseconds
      var dayLength = 24 * 60 * 60 * 1000;

      // Get the current date (without time)
      var currentDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());

      // Get the current date's millisecond for this week
      var currentWeekDayMillisecond = ((currentDate.getDay()) * dayLength);

      // subtract the current date with the current date's millisecond for this week
      var monday = new Date(currentDate.getTime() - currentWeekDayMillisecond + dayLength);

      if (monday > currentDate) {
        // It is sunday, so we need to go back further
        monday = new Date(monday.getTime() - (dayLength * 7));
      }

      return monday;


    },
    dayclick: function(date){
      this.date = date;
      this.openWeek = false;

    },
    update: function (){

      let config = {
        params: {
          date: this.date.toISOString().slice(0, 10)
        }
      }

      axios.get('articleroadmap/dayarr',config).then(response => {

        this.roadmaps = response.data.roadmap;

        let domain = "https://off.radiokrakow.pl/api/"
        let domain_new = "https://off.radiokrakow.pl/"

        $.each(this.roadmaps, function (key, val) {
          val.thumb = domain_new + val.thumb.substr(domain.length)
          $.each(val.users, function (key, user) {
            user.avatar_url = domain_new + user.avatar_url.substr(domain.length)
          });
        });

      });
    }
  },
  created() {

    let monday = this.monday( this.date );

    this.date_arr = [];
    for (let i = 0; i < 7; i++) {

      let result = new Date();
      result.setDate(monday.getDate() + i);

      this.date_arr.push( result )
    }

    this.update();

    // this.menuTu = '<MenuBottom/>';
    // this.refresh();
  },
  watch: {
    date: function (val) {
      this.update();
    },
  }

}
</script>

<style scoped>

</style>