<template>

  <footer>


    <div class="container-fluid">

      <div class="row">
        <div class="col s12"><b></b>
          <ul class="right list-social">
            <li class="list-social-item">

              <a class="app_play" href="https://play.google.com/store/apps/details?id=com.liguidair.app6047&amp;hl=pl&amp;gl=US"></a>
            </li>

            <li class="list-social-item">
              <a class="app_apple" href="https://apps.apple.com/pl/app/radio-krak%C3%B3w/id591093010"></a>
            </li>
          </ul>
          <ul class="left list-social">
            <li class="list-social-item"><a href="https://pl-pl.facebook.com/offradiokrakow" target="_blank">
              <i class="fab fa-facebook-f"></i></a></li>
            <li class="list-social-item"><a href="https://www.youtube.com/channel/UCkk5oRVAJKKiCxsHTT7llsw" target="_blank">
              <i class="fab fa-youtube"></i></a></li>
            <li class="list-social-item"><a href="https://instagram.com/offradiokrakow" target="_blank">

              <i class="fab fa-instagram"></i>
            </a></li>
          </ul>
          <MenuBottom/>
        </div>
      </div>

      <div class="row">
        <div class="col s12">
          <hr>
        </div>

      </div>

      <div class="row">
        <div class="col s12 center-align">
          <a href="https://off.radiokrakow.pl/">
            <img src="@/assets/images/logo-white.png" alt="Radio Kraków" style="height: 40px;">
          </a>
        </div>
      </div>
    </div>

  </footer>

</template>

<script>
import MenuTop from "@/layouts/MenuTop";
import MenuBottom from "@/layouts/MenuBottom";
export default {
  name: "Footer",
  components: {MenuBottom, MenuTop},
  methods: {
    'radioPlayerClick': function () {

      // console.log(123)

      this.$root.$refs.radioPlayer.open()

    }
  }
}
</script>

<style scoped>

</style>