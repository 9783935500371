<template>

  <header>

    <div class="container-fluid">

      <div class="row">
        <div class="col s12">
          <menu>
            <i class="player-click material-icons" v-on:click="radioPlayerClick">play_arrow</i>
            <router-link to="/" class="brand-logo">
              <img src="@/assets/images/logo-white.png" alt="Radio Kraków" class="hide-on-med-and-down">
              <img src="@/assets/images/logo-white-small.png" alt="Radio Kraków" class="hide-on-large-only hide-on-extra-large-only">
            </router-link>
            <RoadmapNow/>
            <MenuTop/>


            <ul class="menu-btns hide-on-large-only hide-on-extra-large-only right">
              <li class="menu-btns__item">
                <a href="!#" data-target="mobile-demo"
                   class="sidenav-trigger color-text menu-btns__link white-text">
                  <i class="material-icons">menu</i>
                </a>
              </li>
            </ul>

<!--            <SearchBar/>-->

          </menu>
        </div>
      </div>

    </div>

  </header>

</template>

<script>
import MenuTop from "@/layouts/MenuTop";
import RoadmapNow from "@/views/home/RoadmapNow";
import SearchBar from "@/components/SearchBar";
export default {
  name: "Header",
  components: {SearchBar, RoadmapNow, MenuTop},
  methods: {
    'radioPlayerClick': function () {

      // console.log(123)

      this.$root.$refs.radioPlayer.open()

    }
  }
}
</script>

<style scoped>

</style>
