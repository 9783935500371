<template>

  <main class="contact">

    <div class="container">
      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Off Radio Kraków" class="breadcrumb">
            Off Radio Kraków
          </router-link>

          <router-link to="/playlist" title="Kontakt" class="breadcrumb">
            Playlist
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <h1 class="big-title">Playlist</h1>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <div class="music_table" v-html="music">

          </div>
        </div>
      </div>
    </div>
    <SectionInfo code="playlist"/>
  </main>
</template>

<script>
import axios from "@/axios";
import SectionInfo from "@/views/home/SectionInfo";

export default {
  name: "Playlist",
  components: {SectionInfo},
  data() {
    return {
      url: 'https://www3.radiokrakow.pl/rds-off/history.txt',
      music: "",
      loaded: false,
    }
  },
  created() {

    axios.get('index/playlist').then(response=>{

      this.music = response.data;

    });

  }

}
</script>

<style scoped>

</style>